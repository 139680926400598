import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@material-ui/core";
import { validateNumber } from "../utils/util";
import { MinusIcon, PlusStandardIcon } from "./SVG";

const ModifierCounter = ({
  initialCount = 1,
  onChange,
  max = 10,
  min = 1,
  isAllowMultiSelect = false,
  multiSelectMax,
}) => {
  const [count, setCount] = useState(initialCount);

  useEffect(() => {
    setCount(initialCount);
  }, [initialCount]);

  const handleChange = (newCount) => {
    const validCount = Math.max(min, Math.min(newCount, getMaxLimit()));
    setCount(validCount);
    onChange(validCount);
  };

  const getMaxLimit = () => {
    if (isAllowMultiSelect) {
      return Math.min(multiSelectMax || max, max);
    }
    return max;
  };

  const decrement = () => {
    if (count > min) {
      handleChange(count - 1);
    }
  };

  const increment = () => {
    const maxLimit = getMaxLimit();
    if (count < maxLimit) {
      handleChange(count + 1);
    }
  };

  return (
    <Grid
      container
      direction='row'
      style={{ border: "1px solid #051D33", height: 50, borderRadius: 5 }}>
      <Grid
        item
        xs={4}
        onClick={decrement}
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}>
        <MinusIcon />
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <TextField
          value={count}
          onChange={(e) => {
            const newValue = validateNumber(e.target.value);
            handleChange(newValue);
          }}
          inputProps={{
            style: {
              textAlign: "center",
              padding: "0",
              width: "100%",
              height: "100%",
              border: "none",
              fontSize: "16px",
              fontWeight: "bold",
            },
          }}
        />
      </Grid>
      <Grid
        item
        xs={4}
        onClick={increment}
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}>
        <PlusStandardIcon />
      </Grid>
    </Grid>
  );
};

export default ModifierCounter;
